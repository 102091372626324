const ATC_AUTH_ERROR_INVALID_PASSWORD = "invalid-password";

const FIREBASE_AUTH_ERROR_EMAIL_ALREADY_IN_USE = "auth/email-already-in-use";
const FIREBASE_AUTH_ERROR_INVALID_EMAIL = "auth/invalid-email";
const FIREBASE_AUTH_ERROR_WEAK_PASSWORD = "auth/weak-password";
const FIREBASE_AUTH_ERROR_OPERATION_NOT_ALLOWED = "auth/operation-not-allowed";
const FIREBASE_AUTH_ERROR_USER_NOT_FOUND = "auth/user-not-found";
const FIREBASE_AUTH_ERROR_WRONG_PASSWORD = "auth/wrong-password";
const FIREBASE_AUTH_ERROR_TOO_MANY_REQUESTS = "auth/too-many-requests";
const FIREBASE_AUTH_ERROR_PROVIDER_ALREADY_LINKED =
  "auth/provider-already-linked";
const FIREBASE_AUTH_ERROR_ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL =
  "auth/account-exists-with-different-credential";

const ATC_VALIDATE_ERROR_INVALID_DATE = "invalid-date";
const ATC_VALIDATE_ERROR_SPECIAL_SYMBOLS_USER_NAME =
  "special-symbols-user-name";
const ATC_VALIDATE_ERROR_SPECIAL_SYMBOLS_JOB = "special-symbols-job";

export {
  ATC_AUTH_ERROR_INVALID_PASSWORD,
  FIREBASE_AUTH_ERROR_EMAIL_ALREADY_IN_USE,
  FIREBASE_AUTH_ERROR_INVALID_EMAIL,
  FIREBASE_AUTH_ERROR_WEAK_PASSWORD,
  FIREBASE_AUTH_ERROR_OPERATION_NOT_ALLOWED,
  FIREBASE_AUTH_ERROR_USER_NOT_FOUND,
  FIREBASE_AUTH_ERROR_WRONG_PASSWORD,
  FIREBASE_AUTH_ERROR_TOO_MANY_REQUESTS,
  FIREBASE_AUTH_ERROR_PROVIDER_ALREADY_LINKED,
  FIREBASE_AUTH_ERROR_ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL,
  ATC_VALIDATE_ERROR_INVALID_DATE,
  ATC_VALIDATE_ERROR_SPECIAL_SYMBOLS_USER_NAME,
  ATC_VALIDATE_ERROR_SPECIAL_SYMBOLS_JOB,
};
