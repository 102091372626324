const CALCULET_DEFAULT_ID = "76ad0be9-3433-49d7-9ea4-af06ca5a0ff9";

// blocked
const BLOCKED_PUBLISH_ID = 0;
const BLOCKED_REPORT_ID = 1;
const BLOCKED_TEMP_ID = 2;

export {
  CALCULET_DEFAULT_ID,
  BLOCKED_PUBLISH_ID,
  BLOCKED_REPORT_ID,
  BLOCKED_TEMP_ID,
};
